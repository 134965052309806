import request from '@/utils/request';

// 列表
export function List(params) {
  return request({
    url: '/admin/form/memorandum/page',
    method: 'GET',
    params
  });
}

// 员工列表
export function GetAdminList(params) {
  return request({
    url: '/admin/collocation/admin/getList',
    method: 'GET',
    params
  });
}
