<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-model-item>
                <a-input v-model="page.key"  placeholder="请输入关键字"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.company_id" placeholder="请选择公司">
                  <a-select-option v-for="(item, index) in companyList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.admin_id" placeholder="请选择销售">
                  <a-select-option v-for="(item, index) in salesList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="search">搜索</a-button>
              </a-form-model-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <template slot="state" slot-scope="text, record">
                  <a-tag color="orange" v-if="text == '1'">未完成</a-tag>
                  <a-tag color="green" v-if="text == '2'">已完成</a-tag>
                </template>
              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        columns: [
          {
            title: '所属员工',
            dataIndex: 'admin_name',
          },
          {
            title: '联系电话',
            dataIndex: 'admin_mobile'
          },
          {
            title: '备注内容',
            dataIndex: 'content',
            width:300
          },

          {
            title: '状态',
            dataIndex: 'state',
            scopedSlots: {customRender: 'state'},
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width:200
          }
        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit:20,
          key: '',
          admin_id: undefined
        },
        recordPage: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
        },
        list: [],
        salesList: [],
        actionVisible:false
      }
    },
    mounted() {
      this.getList();
      this.getSalesList();
    },
    methods: {

      /**
       * 搜索
       * @author wheat
       * */
      search() {
        this.getList();
      },

      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List(this.page)
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },

      /**
       * 获取销售列表
       * @author wheat
       * */
      async getSalesList() {
        let res = await Api.GetAdminList({});
        this.salesList = res.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
     changeRecordPage(currentPage) {
       this.recordPage.start = currentPage;
     }
    },
  }
</script>

<style scoped>

</style>
